// Common
import {
  GENDER_LIST,
  GetAllergyInForm,
  GetFormatBirthDate,
  GetFormatVisitDate,
  GetOperatingOrderInForm,
  GetAdmitOrderInForm,
  GetDoctorCertNumberInForm,
  GetHospitalNameInForm,
  GetRegData,
  GetDiagnosisTreatmentInForm,
  GetNationalityInForm,
  GetCudentMedCertInForm,
  GetBillEncounterSummaryInForm,
  GetIpdMedicalBillInForm,
  MergePdfs,
} from "./CardPrintListCommon";

// Print List API Service
import { GetEncounter } from "./CardPrintListApiService";

// Utils
import { beStringToAdString } from "react-lib/utils/dateUtils";
import moment from "moment";
import { setPDFMedCertificateData } from "../HISV3/DPO/sequence/DoctorCertificate";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// PDF
import {
  AddHeaderSapiensInfoTH,
  AddHeaderSapiensPrintListEN,
  AddHeaderSapiensPrintListTH,
} from "./pdfPrintList/AddHeaderSapiensPrintList";
import { AddHeaderCudentPrintListTH } from "./pdfPrintList/AddHeaderCudentPrintList";
import { default as FormLivingWill } from "./pdfPrintList/FormLivingWill";
import { default as FormConsentPersonalHealth } from "./pdfPrintList/FormConsentPersonalHealth";
import { default as FormConsentDrugScreenTH } from "./pdfPrintList/FormConsentDrugScreenTH";
import { default as FormConsentDrugScreenEN } from "./pdfPrintList/FormConsentDrugScreenEN";
import { default as FormConsentNarcoticUseTH } from "./pdfPrintList/FormConsentNarcoticUseTH";
import { default as FormConsentNarcoticUseEN } from "./pdfPrintList/FormConsentNarcoticUseEN";
import { default as FormConsentHIVTestTH } from "./pdfPrintList/FormConsentHIVTestTH";
import { default as FormConsentHIVTestEN } from "./pdfPrintList/FormConsentHIVTestEN";
import { default as FormConsentBloodTransfusionTH } from "./pdfPrintList/FormConsentBloodTransfusionTH";
import { default as FormConsentBloodTransfusionEN } from "./pdfPrintList/FormConsentBloodTransfusionEN";
import { default as FormConsentPreSedationTH } from "./pdfPrintList/FormConsentPreSedationTH";
import { default as FormConsentPreSedationEN } from "./pdfPrintList/FormConsentPreSedationEN";
import { default as FormConsentOperatingTH } from "./pdfPrintList/FormConsentOperatingTH";
import { default as FormConsentTreatmentProcedure } from "./pdfPrintList/FormConsentTreatmentProcedure";
import { default as FormNewPatientRegistration } from "./pdfPrintList/FormNewPatientRegistration";
import { default as FormConsentAMATH } from "./pdfPrintList/FormConsentAMATH";
import { default as FormConsentAMAEN } from "./pdfPrintList/FormConsentAMAEN";
import { default as FormDNRTH } from "./pdfPrintList/FormDNRTH";
import { default as FormDNREN } from "./pdfPrintList/FormDNREN";
import { default as FormLivingWillTH } from "./pdfPrintList/FormLivingWillTH";
import { default as FormLivingWillEN } from "./pdfPrintList/FormLivingWillEN";
import FormPatientStickerSingle from "./pdfPrintList/FormPatientStickerSingle";
import FormPriceEvaluate from "./pdfPrintList/FormPriceEvaluate";
import FormPriceEvaluateTH from "./pdfPrintList/FormPriceEvaluateTH";
import FormPriceEvaluateEN from "./pdfPrintList/FormPriceEvaluateEN";
import FormConsentOperatingEN from "./pdfPrintList/FormConsentOperatingEN";
import FormPriceEstimateTH from "./pdfPrintList/FormPriceEstimateTH";
import FormClaimForInsurance from "./pdfPrintList/FormClaimForInsurance";
import FormConsentSpinalTH from "./pdfPrintList/FormConsentSpinalTH";
import FormConsentRadioNeuralTH from "./pdfPrintList/FormConsentRadioNeuralTH";
import FormConsentCerviJointTH from "./pdfPrintList/FormConsentCerviJointTH";
import FormConsentPeripheNervTH from "./pdfPrintList/FormConsentPeripheNervTH";
import FormConsentEpiduralSteroidInjectTH from "./pdfPrintList/FormConsentEpiduralSteroidInjectTH";
import FormConsentRadioNeuralEN from "./pdfPrintList/FormConsentRadioNeuralEN";
import FormConsentSpinalEN from "./pdfPrintList/FormConsentSpinalEN";
import FormConsentCerviJointEN from "./pdfPrintList/FormConsentCerviJointEN";
import FormConsentPeripheNervEN from "./pdfPrintList/FormConsentPeripheNervEN";
import FormConsentEpiduralSteroidInjectEN from "./pdfPrintList/FormConsentEpiduralSteroidInjectEN";
import FormConsentPlasmaTH from "./pdfPrintList/FormConsentPlasmaTH";
import FormPainManagementFlowsheet from "./pdfPrintList/FormPainManagementFlowsheet";
import FormIntravenousCareFlowsheet from "./pdfPrintList/FormIntravenousCareFlowsheet";
import FormPatientStickerHnIpd from "./pdfPrintList/FormPatientStickerHnIpd";
import FormCudentMedCertPrintList from "./pdfPrintList/FormCudentMedCertPrintList";
import FormSummaryExpenseInvoiceIpd from "./pdfPrintList/FormSummaryExpenseInvoiceIpd";
import FormListExpenseInvoiceIpd from "./pdfPrintList/FormListExpenseInvoiceIpd";
import FormPatientAdmissionAssessment from "./pdfPrintList/FormPatientAdmissionAssessment";
import FormDischargePlanning from "./pdfPrintList/FormDischargePlanning";
import FormGraphicSheet from "./pdfPrintList/FormGraphicSheet";

type LanguageType = "th" | "en";

type FormDataParamsType = {
  props: any;
  preparedData: PreparedDataType;
  emrId?: string;
  encounterId?: string;
};

type FormFactoryFunctionType = (
  params: FormDataParamsType
) => Promise<{ data: any; formName?: string; language?: LanguageType }>;

type FormattedNameType = {
  withPrefixTh: any;
  withPrefixEn: any;
  withoutPrefixTh: string;
  withoutPrefixEn: string;
};

type PreparedDataType = Partial<{
  prepCreatedDateTh: any;
  prepCreatedDateEn: any;
  prepSignedDateTh: any;
  prepSignedDateEn: any;
  prepPatientName: FormattedNameType;
  prepPatientAge: any;
  prepPatientBirthDateEn: string;
  prepPatientAddress: any;
  prepDoctorName: FormattedNameType;
  haveHeader: boolean;
  printer: string;
}>;

type FormDataType = {
  data: {
    items: any;
  };
  formName?: string;
  language?: string;
} | null;

type HeaderDataType = {
  createdFormDate: string;
  formName?: string;
  patientInfo: any;
  footer?: string | null;
};

/**
 * name - ชื่อสำหรับแสดงในรายงาน (บางรายงานไม่จำเป็นต้องมีชื่อไฟล์ ให้ set เป็น `""`)
 *
 * formGen - PDF จาก pdfPrintList (จะมีทั้ง pdfMake และ static PDF)
 */
const FORM_NAME_LIST: Record<string, string> = {
  living_will:
    "หนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขที่เป็นเพียง\nเพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือเพื่อยุติการทรมานจากการเจ็บป่วย",
  cons_pers_health: "หนังสือแสดงความยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ\nของบุคคลทางอิเล็กทรอนิกส์",
  cons_drug_screen_th: "หนังสือแสดงการยินยอมตรวจหาสารเสพติด",
  cons_drug_screen_en: "Consent Drug Screen Test",
  cons_narcotic_use_th:
    "หนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวด\nชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง",
  cons_narcotic_use_en: "Consent for Narcotic Use In Chronic Non-Cancer Pain",
  cons_hiv_test_th: "หนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี",
  cons_hiv_test_en: "Informed Consent to HIV Blood Test",
  cons_blood_trans_th: "หนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต",
  cons_blood_trans_en: "Consent for Blood/Blood Product Transfusion",
  cons_pre_sedat_th:
    "หนังสือยินยอมก่อนการให้ยาระงับปวด\nหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด",
  cons_pre_sedat_en: "Pre Procedural Sedation and Pre Anesthesia\nInformed Consent",
  cons_operating_th: "หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ",
  cons_treat_proc: "หนังสือรับทราบ และยินยอมรับการรักษา ฟื้นฟู หรือทำหัตถการ",
  consent_ama_th: "หนังสือการปฏิเสธการรักษา",
  consent_ama_en: "Do Not Resuscitate Request",
  dnr_th: "Do Not Resuscitate Request (TH)",
  dnr_en: "Do Not Resuscitate Request (EN)",
  living_will_th: "Living Will (TH)",
  living_will_en: "Living Will (EN)",
};

export const FALLBACK_PDF_PATHS: Record<string, string> = {
  pt_ceteficate_EN: "/static/pdf/printList/physical_cert_EN.pdf",
  pt_ceteficate_TH: "/static/pdf/printList/physical_cert_TH.pdf",
};

/**
 * Form กระดาษเปล่าสำหรับทดสอบ Header หรือ ใด ๆ (code: cudent_header_form ใช้)
 * @param props - props from CardPrintList
 * @returns
 */
const EmptyForm = (props: any) => {
  return {
    content: [],
    pageSize: `A4`,
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
  };
};

/**========================================================================
 **                          PDF FORM MAPPING
 *========================================================================**/

export const FORM_PDFDATA_MAPPING: Record<string, any> = {
  // Pdfmake
  living_will: FormLivingWill,
  cons_pers_health: FormConsentPersonalHealth,
  cons_drug_screen_th: FormConsentDrugScreenTH,
  cons_drug_screen_en: FormConsentDrugScreenEN,
  cons_narcotic_use_th: FormConsentNarcoticUseTH,
  cons_narcotic_use_en: FormConsentNarcoticUseEN,
  cons_hiv_test_th: FormConsentHIVTestTH,
  cons_hiv_test_en: FormConsentHIVTestEN,
  cons_blood_trans_th: FormConsentBloodTransfusionTH,
  cons_blood_trans_en: FormConsentBloodTransfusionEN,
  cons_pre_sedat_th: FormConsentPreSedationTH,
  cons_pre_sedat_en: FormConsentPreSedationEN,
  cons_operating_th: FormConsentOperatingTH,
  cons_treat_proc: FormConsentTreatmentProcedure,
  new_patient_register: FormNewPatientRegistration,
  consent_ama_th: FormConsentAMATH,
  consent_ama_en: FormConsentAMAEN,
  dnr_th: FormDNRTH,
  dnr_en: FormDNREN,
  living_will_th: FormLivingWillTH,
  living_will_en: FormLivingWillEN,
  cudent_header_form: EmptyForm,
  // Static
  patient_sticker: FormPatientStickerSingle,
  patient_sticker_ipd: FormPatientStickerHnIpd,
  price_evaluate: FormPriceEvaluate,
  price_evaluate_th: FormPriceEvaluateTH,
  price_evaluate_en: FormPriceEvaluateEN,
  cons_operating_en: FormConsentOperatingEN,
  price_estimate_th: FormPriceEstimateTH,
  claim_form: FormClaimForInsurance,
  pain_management_fs: FormPainManagementFlowsheet,
  intravenous_care_fs: FormIntravenousCareFlowsheet,
  cudent_med_cert: FormCudentMedCertPrintList,
  cons_spinal_th: FormConsentSpinalTH,
  cons_radiofrq_neu_th: FormConsentRadioNeuralTH,
  cons_cervi_joint_th: FormConsentCerviJointTH,
  cons_periphe_nerv_th: FormConsentPeripheNervTH,
  cons_esi_th: FormConsentEpiduralSteroidInjectTH,
  summary_expense_ipd: [FormSummaryExpenseInvoiceIpd, FormListExpenseInvoiceIpd],
  cons_radiofrq_neu_en: FormConsentRadioNeuralEN,
  cons_spinal_en: FormConsentSpinalEN,
  cons_steroid_spir_en: FormConsentCerviJointEN,
  cons_anes_ster_en: FormConsentPeripheNervEN,
  cons_esi_en: FormConsentEpiduralSteroidInjectEN,
  cons_plasma_th: FormConsentPlasmaTH,
  assessment_form_en: FormPatientAdmissionAssessment,
  discharge_planning: FormDischargePlanning,
  graphic_sheet: FormGraphicSheet,
};

export const CUSTOM_PDF_LIST = ["summary_expense_ipd"];

/**========================================================================
 **                        COMMON DATA IN FORM
 *========================================================================**/

/**
 * เรียกใช้ Common Data สำหรับแสดงในเอกสาร
 * @param patientInfo - props patientInfo from CardPrintList `props.patientInfo`
 * @param preparedData - `preparedData`
 * @param language - Language in PDF
 * @param additionData - Misc data
 * @param isStatic - เป็น static data หรือไม่ (กรณีไม่ได้ใช้ pdfMake สร้าง)
 * @returns
 */
const CreateFormBaseData = async (
  patientInfo: any,
  preparedData: PreparedDataType,
  language: LanguageType = "th",
  additionData: any = {},
  isStatic: boolean = false
) => {
  const isEng = language === "en";
  const patientEncounter = additionData?.encounterNumber || patientInfo?.encounter_no || "-";
  const formatVisitDate =
    GetFormatVisitDate(
      patientInfo?.admit_date,
      patientInfo?.encounter_created,
      isEng,
      ...(isStatic ? [true] : []) //*
    ) || "-";
  const formatPatient = isEng
    ? preparedData.prepPatientName?.withoutPrefixEn
    : preparedData.prepPatientName?.withoutPrefixTh;
  const formatDoctor = isEng
    ? preparedData.prepDoctorName?.withoutPrefixEn
    : preparedData.prepDoctorName?.withoutPrefixTh;
  const patientFullName = isEng
    ? preparedData.prepPatientName?.withPrefixEn
    : preparedData.prepPatientName?.withPrefixTh;
  const doctorFullName = isEng
    ? preparedData.prepDoctorName?.withPrefixEn
    : preparedData.prepDoctorName?.withPrefixTh;
  const formatGender = isEng
    ? GENDER_LIST[patientInfo?.gender]?.name_en
    : GENDER_LIST[patientInfo?.gender]?.name_th || patientInfo?.gender;

  let baseData = {
    // Encounter Data
    ...patientInfo,
    patientEncounter,
    formatVisitDate,
    // Patient Age
    birthdateAd: beStringToAdString(patientInfo?.birthdate),
    ageYear: preparedData.prepPatientAge[0],
    ageMonth: preparedData.prepPatientAge[1],
    ageDay: preparedData.prepPatientAge[2],
    // Patient & Doctor Name
    formatPatient,
    formatDoctor,
    patientFullName,
    doctorFullName,
    // Printer
    printer: preparedData.printer,
    language,
    ...(!isStatic && { haveHeader: preparedData.haveHeader }), // static ไม่ใช้
    ...(isStatic && { formatGender }),
    ...additionData,
  };

  if (isStatic) {
    console.log(`[Print List Data Helper] Create Static Data`);
    return {
      items: baseData,
      detail: {
        // Encounter Data
        number: patientEncounter,
        visit_date: formatVisitDate,
        // Patient Info
        patientName: patientFullName,
        hn: patientInfo.hn,
        patient_birthdate: GetFormatBirthDate(patientInfo?.birthdate, isEng),
        patient_age: preparedData.prepPatientAge[0],
        patient_gender_name: formatGender || "-",
        telMobile: patientInfo?.present_address?.tel_mobile || "-",
        // Doctor Info
        doctorName: doctorFullName,
        ...additionData,
      },
    };
  }

  return { items: baseData };
};

/**
 * สร้าง Common data ที่เป็นวันที่ - เวลา สำหรับแสดงในเอกสาร
 * @param preparedData - `preparedData`
 * @param isEng
 * @returns
 */
const CreateDateInfo = (preparedData: PreparedDataType, isEng: boolean = false) => ({
  signedDate: isEng ? preparedData.prepSignedDateEn[0] : preparedData.prepSignedDateTh[0],
  signedTime: isEng ? preparedData.prepSignedDateEn[1] : `${preparedData.prepSignedDateTh[1]} น.`,
  printDay: isEng ? preparedData.prepCreatedDateEn[0] : preparedData.prepCreatedDateTh[0],
  printMonth: isEng ? preparedData.prepCreatedDateEn[2] : preparedData.prepCreatedDateTh[2],
  printYear: isEng ? preparedData.prepCreatedDateEn[3] : preparedData.prepCreatedDateTh[3],
  printTime: isEng ? preparedData.prepCreatedDateEn[4] : preparedData.prepCreatedDateTh[4],
});

/**
 * ใช้สำหรับสร้าง data ของ PdfMake Pattern (ที่มีการใช้ Header)
 * @param formName - ชื่อของเอกสาร (สำหรับแสดงใน Header)
 * @param baseData - data
 * @returns
 */
const CreateFormData = async (
  formName: string,
  baseData: any
): Promise<{ data: any; formName: string; language: LanguageType }> => {
  // console.log("[Print List Data Helper] Create Form Data: ", baseData);
  return {
    data: { items: baseData.items },
    formName,
    language: baseData.items.language ?? null,
  };
};

/**
 * สำหรับเรียกใช้ Data ด้วย code จาก document type
 * @param formKey - code จาก document type
 * @param params - params for get the data { `props, preparedData, emrId, encounterId` }
 * @returns Data of Form (ex: Data of "living_will" (`code`))
 */
export const GetFormData = async (formKey: string, params: FormDataParamsType) => {
  if (!formFactory[formKey]) {
    console.warn(
      `[Print List Form Factory] has no supported function for "${formKey}" (Maybe is non-data PDF)`
    );
    return null;
  }

  return formFactory[formKey](params);
};

/**========================================================================
 **                           CREATE PDF (docPdfDef)
 *========================================================================**/

/**
 * สร้าง PDF และเพิ่ม Header
 * @param formGen - PDF File (ex: `FormLivingWill`)
 * @param pdfData - PDF Data as Pattern (data, formName, language)
 * @param isEnableHeader - Enable Header (depened on config)
 * @param company - Company config
 * @param headerData - Header Data (ex: formName, Info,...)
 * @returns
 */
export const CreateWithHeaderForm = async (
  formGen: Function,
  pdfData: FormDataType,
  isEnableHeader: boolean,
  company: string,
  headerData: HeaderDataType
) => {
  console.log(`[Print List Data Helper] Create PDF Header: `, {
    formGen,
    pdfData,
    isEnableHeader,
    company,
    headerData,
  });

  let docPdfDef = await formGen({ ...pdfData?.data });

  if (isEnableHeader && formGen === FormNewPatientRegistration && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensInfoTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
    });
  } else if (isEnableHeader && pdfData?.language === "th" && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensPrintListTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  } else if (isEnableHeader && pdfData?.language === "en" && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensPrintListEN(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  } else if (isEnableHeader && pdfData?.language === "th" && company === "CU") {
    docPdfDef = await AddHeaderCudentPrintListTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  }
  // else if (isEnableHeader && pdfData?.language === "th" && company === "CU") {
  //   docPdfDef = await AddHeaderCudentPrintListEN(docPdfDef, {
  //     createdDate: headerData.createdFormDate,
  //     formName: pdfData?.formName,
  //     patientInfo: pdfData?.data.items,
  //     footer: headerData?.footer,
  //   });
  // }

  return docPdfDef;
};

/**
 * สำหรับกรณีมีการใช้ Form PDF มากกว่า 1 ให้ Merge เป็น PDF เดียวกัน
 * @param formList - Form ที่ใช้ในการ Merge
 * @param data - Data ที่ใช้สำหรับใส่ใน Form
 * @returns
 */
export const CreateMergedForm = async (formList: any[], data: any) => {
  if (!Array.isArray(formList) || formList.length === 0) {
    console.warn(`[Print List Data Helper] Cannot Create Form `, formList);
    return { content: [] };
  }

  const pdfMake = await getPdfMake(true);

  try {
    const pdfUint8Arrays = await Promise.all(
      formList.map(async (createPdf) => {
        const pdfDocDef = await createPdf(data);
        return new Promise<Uint8Array>((resolve) => {
          pdfMake.createPdf(pdfDocDef).getBuffer((buffer) => resolve(new Uint8Array(buffer)));
        });
      })
    );

    if (pdfUint8Arrays.length > 0) {
      const mergedPdf = MergePdfs(pdfUint8Arrays);
      return mergedPdf;
    } else {
      console.error(`[Print List Data Helper] Cannot Create Form (maybe PDFs are missing)`);
      return { content: [] };
    }
  } catch (error) {
    console.error(`[Print List Data Helper] Error creating PDFs: `, error);
    return { content: [] };
  }
};

/**========================================================================
 **                             GET DATA
 *========================================================================**/

const GetCudentHeaderData = async ({ props, preparedData }: FormDataParamsType) => {
  const additionData = {
    haveHeader: true, //* Forced
    formAddress: preparedData.prepPatientAddress,
    ...CreateDateInfo(preparedData),
  };

  return CreateFormData(
    "",
    await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData)
  );
};

const GetLivingWillData = async ({ props, preparedData }: FormDataParamsType) => {
  return CreateFormData(
    FORM_NAME_LIST.living_will,
    await CreateFormBaseData(props.patientInfo, preparedData)
  );
};

const GetLivingWillNewData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";
  const doctorNumber = await GetDoctorCertNumberInForm(
    props.controller,
    props.patientInfo,
    props.masterOptions
  );
  const getPatientNationality = await GetNationalityInForm(props.patientInfo, props.masterOptions);
  const patientNationality = isEng
    ? getPatientNationality?.nationalityEn
    : getPatientNationality?.nationalityTh;

  const additionData = {
    doctorNumber,
    patientNationality,
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.living_will_en : FORM_NAME_LIST.living_will_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentPersonalHealthData = async ({ props, preparedData }: FormDataParamsType) => {
  return CreateFormData(
    FORM_NAME_LIST.cons_pers_health,
    await CreateFormBaseData(props.patientInfo, preparedData)
  );
};

const GetDNRData = async ({ props, preparedData }: FormDataParamsType, language: LanguageType) => {
  const isEng = language === "en";

  const haveFooter = isEng
    ? `This form should be applied "Only One Time of Admission"`
    : "แบบฟอร์มนี้ใช้สำหรับ admission ต่อครั้งเท่านั้น";

  const additionData = {
    haveFooter,
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.dnr_en : FORM_NAME_LIST.dnr_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentAMAData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.consent_ama_en : FORM_NAME_LIST.consent_ama_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetNewPatientRegisterData = async ({ props, preparedData }: FormDataParamsType) => {
  const patientInfo = props.patientInfo ?? {};
  const getHospitalName = await GetHospitalNameInForm(props.controller, "th");
  const getAllergy = await GetAllergyInForm(props.controller, patientInfo);
  const regData = await GetRegData(props.masterOptions);

  // console.log(`Reg Data: `, regData); //todo masterOptions บางตัวไม่ถูกเรียกทำให้แสดงข้อมูลไม่ถูก

  const citizenSplit =
    patientInfo?.citizen_no.length > 0 && patientInfo?.citizen_no !== "Non citizen identity"
      ? patientInfo?.citizen_no?.split("")
      : [];

  const zipcodeSplit =
    patientInfo?.present_address && Object.keys(patientInfo?.present_address).length > 0
      ? patientInfo?.present_address?.zipcode?.split("")
      : [];

  const ownerSplit =
    patientInfo?.relative_address && Object.keys(patientInfo?.relative_address).length > 0
      ? patientInfo?.relative_address?.owner_name?.split(" ")
      : [];

  const relativeZipcodeSplit =
    patientInfo?.relative_address && Object.keys(patientInfo?.relative_address).length > 0
      ? patientInfo?.relative_address?.zipcode?.split("")
      : [];

  const additionData = {
    ...CreateDateInfo(preparedData),
    hospital: getHospitalName,
    allergy: getAllergy ? getAllergy.formattedAllergyData : "",
    regData,
    citizen_split: citizenSplit,
    zipcode_split: zipcodeSplit,
    owner_split: ownerSplit,
    relative_zipcode_split: relativeZipcodeSplit,
  };

  return CreateFormData(
    FORM_NAME_LIST.new_patient_register,
    await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData)
  );
};

const GetConsentOperatingThData = async ({ props, preparedData, emrId }: FormDataParamsType) => {
  const getOperatingOrderData = await GetOperatingOrderInForm(props.controller, emrId, "th");
  const operationOneLine = getOperatingOrderData
    ? getOperatingOrderData.operation_one_line.trim()
    : "";
  const enableDecorationOperation = operationOneLine.length > 0;
  const formatOperation = enableDecorationOperation
    ? operationOneLine
    : "...................................................................................................................................................................................................................\n...................................................................................................................................................................................................................";

  const additionData = {
    ...CreateDateInfo(preparedData),
    operatingData: getOperatingOrderData,
    enableDecorationOperation,
    formatOperation,
  };

  return CreateFormData(
    FORM_NAME_LIST.cons_operating_th,
    await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData)
  );
};

const GetConsentTreatmentProcedureData = async ({
  props,
  preparedData,
  emrId,
}: FormDataParamsType) => {
  const diagnosisData = await GetDiagnosisTreatmentInForm(props.controller, emrId);

  const additionData = {
    ...CreateDateInfo(preparedData),
    diagnosisData,
    formatIcd10Code: diagnosisData ? diagnosisData?.icd10_code : "",
    formatIcd10Term: diagnosisData ? diagnosisData?.icd10_term : "",
  };

  return CreateFormData(
    FORM_NAME_LIST.cons_treat_proc,
    await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData)
  );
};

const GetConsentPreSedationData = async (
  { props, preparedData, emrId }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";
  const hospital = await GetHospitalNameInForm(props.controller, language);
  const operatingData = await GetOperatingOrderInForm(props.controller, emrId, language);

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    hospital,
    operatingData,
    formatOperation: isEng
      ? operatingData?.formattedOperation
      : operatingData?.operation_regexp || " ",
    formatIcd9: operatingData?.icd9_regexp || " ",
    formatIcd10: operatingData?.icd10_regexp || operatingData?.diagnosis_summary?.slice(3) || " ", //* ถ้าผิดให้ปรับเป็นแบบเดิม
    formatAnes: operatingData?.anes_name || " ",
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.cons_pre_sedat_en : FORM_NAME_LIST.cons_pre_sedat_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentBloodTransmutionData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.cons_blood_trans_en : FORM_NAME_LIST.cons_blood_trans_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentHIVTestData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.cons_hiv_test_en : FORM_NAME_LIST.cons_hiv_test_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentNarcoticUseData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.cons_narcotic_use_en : FORM_NAME_LIST.cons_narcotic_use_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

const GetConsentDrugScreenData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return CreateFormData(
    isEng ? FORM_NAME_LIST.cons_narcotic_use_en : FORM_NAME_LIST.cons_narcotic_use_th,
    await CreateFormBaseData(props.patientInfo, preparedData, language, additionData)
  );
};

// *-- Static --* //
const GetPatientStickerData = async ({ props }: FormDataParamsType) => {
  const patientInfo = props.patientInfo ?? {};

  return {
    data: {
      items: patientInfo,
    },
  };
};

// *-- intravenous_care_fs, pain_management_fs --* //
const GetSapiensIPDData = async (
  { props, preparedData, encounterId }: FormDataParamsType,
  language: LanguageType
) => {
  const getEncounterData = await GetEncounter(props.controller, encounterId);
  const getAdmitOrderData = await GetAdmitOrderInForm(props.controller, encounterId);
  const getAllergyData = await GetAllergyInForm(props.controller, props.patientInfo);

  const additionData = {
    ipd_room: getAdmitOrderData ? getAdmitOrderData.room_no : "-",
    allergies: getAllergyData ? getAllergyData.formattedAllergyData : "",
    divisionName: getEncounterData ? getEncounterData.division_name : "-",
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, language, additionData, true),
  };
};

const GetSapiensOPDData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, language, additionData, true),
  };
};

const GetClaimFormData = async ({ props, preparedData, encounterId }: FormDataParamsType) => {
  const getEncounterData = await GetEncounter(props.controller, encounterId);
  const getAdmitOrderData = await GetAdmitOrderInForm(props.controller, encounterId);

  const additionData = {
    ...CreateDateInfo(preparedData),
    formatPatientEn: preparedData.prepPatientName?.withoutPrefixEn,
    patientFullNameEn: preparedData.prepPatientName?.withPrefixEn,
    formatDoctorEn: preparedData.prepDoctorName?.withoutPrefixEn,
    doctorFullNameEn: preparedData.prepDoctorName?.withPrefixEn,
    admitOrderData: getAdmitOrderData,
    underlyingDisease: getEncounterData ? getEncounterData.underlying_disease : null,
    opdDischarge: getEncounterData ? getEncounterData.ended : null,
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData, true),
  };
};

const GetPriceEstimateData = async ({ props, preparedData, emrId }: FormDataParamsType) => {
  const getOperatingOrderData = await GetOperatingOrderInForm(props.controller, emrId, "th");
  const getAllergy = await GetAllergyInForm(props.controller, props.patientInfo);

  const additionData = {
    ...CreateDateInfo(preparedData),
    patientAllergy: getAllergy ? getAllergy.formattedAllergyData : "",
    attendingDoctor: getOperatingOrderData
      ? getOperatingOrderData.primary_doctor_name
      : preparedData.prepDoctorName?.withoutPrefixTh,
    operatingRoom: getOperatingOrderData ? getOperatingOrderData.operating_room_no : "-", //*
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, "th", additionData, true),
  };
};

const GetConsentOperatingEnData = async ({ props, preparedData }: FormDataParamsType) => {
  const additionData = {
    patientBirthdateEN: preparedData.prepPatientBirthDateEn,
    ...CreateDateInfo(preparedData, true),
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, "en", additionData, true),
  };
};

const GetPriceEvaluateData = async (
  { props, preparedData }: FormDataParamsType,
  language: LanguageType
) => {
  const isEng = language === "en";

  const getDoctorCertNumber = await GetDoctorCertNumberInForm(
    props.controller,
    props.patientInfo,
    props.masterOptions
  );

  const additionData = {
    ...CreateDateInfo(preparedData, isEng),
    ...(isEng && { doctorNumber: getDoctorCertNumber }),
    ...(isEng && { patientBirthdateEN: preparedData.prepPatientBirthDateEn }),
  };

  return {
    data: await CreateFormBaseData(props.patientInfo, preparedData, language, additionData, true),
  };
};

const GetCudentMedCertData = async ({ props, encounterId }: FormDataParamsType) => {
  // Print Datetime in Med Cert
  const momentDate = moment();
  const date = momentDate.format("YYYY-MM-DD");
  const time = momentDate.format("HH:mm");

  // Get Med Cert Data
  const getMedCertData = await GetCudentMedCertInForm(
    props.controller,
    props.encounterNumber,
    props.patientId
  );

  // Get Encounter Data
  let getEncounterData: any = {};
  let data: any = {};

  const defaultData = {
    code: "-",
    print_datetime: `${date} [${time}]`,
  };

  if (!getMedCertData) {
    getEncounterData = await GetEncounter(props.controller, encounterId);
    data = setPDFMedCertificateData(getEncounterData, defaultData);
  } else {
    getEncounterData = await GetEncounter(props.controller, getMedCertData?.data?.encounter);

    //* เช็ค encounter number ที่ med cert ดึงมาใช้
    // console.log(
    //   `[Print List Data Helper] Med Cert from Encounter number: `,
    //   getEncounterData?.number
    // );

    data = setPDFMedCertificateData(getEncounterData, {
      ...getMedCertData?.data?.data,
      code: getMedCertData?.code,
    });
  }

  return { data };
};

const GetSummaryExpenseIPDData = async ({
  props,
  preparedData,
  emrId,
  encounterId,
}: FormDataParamsType) => {
  // Addition Summary Func
  const addSummaryPrice = (data: any) => {
    if (!data) {
      console.warn(`[Print List Data Helper] IPD Medical Invoice Report Have No Data!`);
      return null;
    }

    let totalSummaryPrice = 0;
    let totalSumNonClaimable = 0;
    let totalSumClaimable = 0;

    data.items = data.items.map((bill: any) => {
      const summaryPrice = bill.items.reduce(
        (total: number, item: any) => total + item.sum_price,
        0
      );
      const summaryClaimable = bill.items.reduce(
        (total: number, item: any) => total + item.sum_claimable,
        0
      );
      const sumNonClaimable = bill.items.reduce(
        (total: number, item: any) => total + item.sum_non_claimable,
        0
      );

      totalSummaryPrice += summaryPrice;
      totalSumClaimable += summaryClaimable;
      totalSumNonClaimable += sumNonClaimable;

      return { ...bill, summary_price: summaryPrice };
    });

    data.summary = {
      total_summary_price: totalSummaryPrice,
      total_sum_claimable: totalSumClaimable,
      total_sum_non_claimable: totalSumNonClaimable,
    };

    return data;
  };

  // Name Apperance Func
  const showName = (isCitizenType: string) => {
    if (isCitizenType === "T") {
      return preparedData.prepPatientName?.withPrefixTh || patientInfo?.full_name;
    }

    return preparedData.prepPatientName?.withPrefixEn || patientInfo?.full_name;
  };

  const patientInfo = props.patientInfo ?? {};
  const patientId = props.patientId ?? patientInfo?.id;
  const getEncounterData = await GetEncounter(props.controller, encounterId);
  const getAdmitOrderData = await GetAdmitOrderInForm(props.controller, encounterId);
  const billEncounterSummaryData = await GetBillEncounterSummaryInForm(
    props.controller,
    patientId,
    props.encounterNumber
  );
  const ipdMedicalBillData = await GetIpdMedicalBillInForm(
    props.controller,
    encounterId,
    patientId
  );
  const diagnosisData = await GetDiagnosisTreatmentInForm(props.controller, emrId);

  const additionData = {
    patientName: showName(patientInfo?.citizen_type),
    doctorName:
      preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
    ipd_room: getAdmitOrderData ? getAdmitOrderData?.room_no : "-",
    divisionName: getEncounterData ? getEncounterData?.division_name : "-",
    roomType: getAdmitOrderData?.ward_type_name || "-",
    coverage: getEncounterData?.coverage || "-",
    startAdmit: getEncounterData?.created || "-",
    startAdmit_date: getEncounterData?.created?.split(" ")
      ? getEncounterData?.created?.split(" ")[0]
      : getAdmitOrderData?.admit_date__date || "-",
    dayDuration: getAdmitOrderData?.day_of_stay,
    hourDuration: getAdmitOrderData?.hour_of_stay,
    total: billEncounterSummaryData?.total, // issue 72812
    ipdMedicalBil: addSummaryPrice(ipdMedicalBillData),
    diagnosisData,
    formatIcd10Code: diagnosisData?.icd10_code,
    formatIcd10Term: diagnosisData?.icd10_term,
  };

  return {
    data: await CreateFormBaseData(patientInfo, preparedData, "th", additionData, true),
  };
};

/**========================================================================
 **                           FORM FACTORY
 *========================================================================**/

const formFactory: Record<string, FormFactoryFunctionType> = {
  // * --- วิธีแบบ pdfMake (ทั่วไป)
  living_will: GetLivingWillData,
  living_will_th: (params) => GetLivingWillNewData(params, "th"),
  living_will_en: (params) => GetLivingWillNewData(params, "en"),
  cons_pers_health: GetConsentPersonalHealthData,
  dnr_th: (params) => GetDNRData(params, "th"),
  dnr_en: (params) => GetDNRData(params, "en"),
  consent_ama_th: (params) => GetConsentAMAData(params, "th"),
  consent_ama_en: (params) => GetConsentAMAData(params, "en"),
  new_patient_register: GetNewPatientRegisterData,
  cons_operating_th: GetConsentOperatingThData,
  cons_treat_proc: GetConsentTreatmentProcedureData,
  cons_pre_sedat_th: (params) => GetConsentPreSedationData(params, "th"),
  cons_pre_sedat_en: (params) => GetConsentPreSedationData(params, "en"),
  cons_blood_trans_th: (params) => GetConsentBloodTransmutionData(params, "th"),
  cons_blood_trans_en: (params) => GetConsentBloodTransmutionData(params, "en"),
  cons_hiv_test_th: (params) => GetConsentHIVTestData(params, "th"),
  cons_hiv_test_en: (params) => GetConsentHIVTestData(params, "en"),
  cons_narcotic_use_th: (params) => GetConsentNarcoticUseData(params, "th"),
  cons_narcotic_use_en: (params) => GetConsentNarcoticUseData(params, "en"),
  cons_drug_screen_th: (params) => GetConsentDrugScreenData(params, "th"),
  cons_drug_screen_en: (params) => GetConsentDrugScreenData(params, "en"),
  cudent_header_form: GetCudentHeaderData,
  // * --- วิธีแบบ Static
  patient_sticker: GetPatientStickerData,
  patient_sticker_ipd: GetPatientStickerData,
  intravenous_care_fs: (params) => GetSapiensIPDData(params, "en"),
  pain_management_fs: (params) => GetSapiensIPDData(params, "en"),
  claim_form: GetClaimFormData,
  price_estimate_th: GetPriceEstimateData,
  cons_operating_en: GetConsentOperatingEnData,
  price_evaluate: (params) => GetPriceEvaluateData(params, "en"),
  price_evaluate_th: (params) => GetPriceEvaluateData(params, "th"),
  price_evaluate_en: (params) => GetPriceEvaluateData(params, "en"),
  cudent_med_cert: GetCudentMedCertData,
  cons_spinal_th: (params) => GetSapiensOPDData(params, "th"),
  cons_spinal_en: (params) => GetSapiensOPDData(params, "en"),
  cons_radiofrq_neu_th: (params) => GetSapiensOPDData(params, "th"),
  cons_radiofrq_neu_en: (params) => GetSapiensOPDData(params, "en"),
  cons_cervi_joint_th: (params) => GetSapiensOPDData(params, "th"),
  cons_periphe_nerv_th: (params) => GetSapiensOPDData(params, "th"),
  cons_esi_th: (params) => GetSapiensOPDData(params, "th"),
  cons_esi_en: (params) => GetSapiensOPDData(params, "en"),
  summary_expense_ipd: GetSummaryExpenseIPDData,
  cons_steroid_spir_en: (params) => GetSapiensOPDData(params, "en"),
  cons_anes_ster_en: (params) => GetSapiensOPDData(params, "en"),
  cons_plasma_th: (params) => GetSapiensOPDData(params, "th"),
  assessment_form_en: (params) => GetSapiensIPDData(params, "en"),
  discharge_planning: (params) => GetSapiensIPDData(params, "en"),
  graphic_sheet: (params) => GetSapiensIPDData(params, "en"),
};
